import { useTranslation } from "react-i18next"
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, ListItem, OrderedList, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"

import GModal from "components/ui/modal/Modal"
import { useEffect, useState } from "react";
import { axios } from "services/axios";

const DetailsModal = ({ data, onClose }) => {
  const { t } = useTranslation();

  const [user, setUser] = useState({});

  const getUserDetails = async () => {
    try {
      const { data: theData } = await axios.get(`/GetT3?g=${JSON.stringify({ UserId: data })}`);
      const user = theData[0] && JSON.parse(theData[0].jsonData);
      setUser(user || {});
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <GModal
      onClose={onClose}
      size="3xl"
      modalHeader={t("pages.offers.details")}
      modalContent={(
        user && Object.keys(user).length > 0 ? (
          <Box p={4}>
            <Stack spacing={6}>
              <Tabs variant='unstyled'>
                <TabList gap={4}>
                  <Tab
                    flex="1" bg="white" borderBottom="1px solid #eee" boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                    _selected={{ borderBottom: "2px solid", borderColor: "blue.500" }} textTransform="uppercase"
                  >
                    {t("pages.offers.user_summary")}
                  </Tab>
                  <Tab
                    flex="1" bg="white" borderBottom="1px solid #eee" boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                    _selected={{ borderBottom: "2px solid", borderColor: "blue.500" }} textTransform="uppercase"
                  >
                    {t("pages.offers.document_details")}
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Accordion>
                      {Object.keys(user[0]).map(el => (
                        el !== "UserId" && (
                          <AccordionItem border="none">
                            <h2>
                              <AccordionButton bg="white" borderBottom="1px solid #eee" boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" paddingBlock={4}>
                                <Box as="span" flex='1' textAlign='left' textTransform="capitalize">
                                  {t(`pages.offers.${el}`)}
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              <OrderedList>
                                {Object.keys(user[0][el][0]).map(obj => (
                                  <ListItem marginBlock={2}>
                                    <Flex>
                                      <Box flex="1">{t(`pages.offers.${obj}`)}</Box>
                                      <Box flex="1">{user[0][el][0][obj]}</Box>
                                    </Flex>
                                  </ListItem>
                                ))}
                              </OrderedList>
                            </AccordionPanel>
                          </AccordionItem>
                        )
                      ))}
                    </Accordion>
                  </TabPanel>
                  <TabPanel>
                    <p>one!</p>
                  </TabPanel>
                </TabPanels>
              </Tabs>
              <Flex justifyContent="flex-end">
                <Button
                  textTransform="capitalize"
                  onClick={onClose}
                >
                  {t("general.close")}
                </Button>
              </Flex>
            </Stack>
          </Box>
        ) : (
          <Flex justifyContent="center" p={4} bg="light">
            <Spinner />
          </Flex>
        )
      )}
    />
  )
}

export default DetailsModal