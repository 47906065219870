import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { Box, Button, Checkbox, Container, Flex, Heading, Image, SimpleGrid, Stack, Link as CLink, Text } from "@chakra-ui/react"
import Input from "../../../components/form/Input";
import logo from "../../../assets/images/logo.png";
import authImg from "../../../assets/images/auth-img.jpg";
import { login } from "../services/authService";
import routes from "../../../navigations/routes";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm();
  const { t } = useTranslation();

  const loginValidationSchema = {
    email: {
      required: t("validation.required")
    },
    password: {
      required: t("validation.required")
    }
  }

  return (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      <Container maxW="container.lg">
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          bg="light" boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          paddingInline={8} paddingBlock={14} borderRadius={6}
        >
          <Box
            flex="1" display={{ base: "none", md: "block" }}
          >
            <Image
              src={logo}
              alt="brand"
              width="120px"
            />
            <Image
              src={authImg}
              alt="brand"
              maxHeight="400px"
              margin="auto"
              borderRadius={6}
              objectFit="contain"
            />
          </Box>
          <Box flex="1" paddingInline={{ base: 2, md: 8 }} >
            <form onSubmit={handleSubmit(values => {
              dispatch(login(values))
                .unwrap()
                .then(_ => navigate(`/${routes.dashboard}`))
                .catch(_ => alert("invalid login"))
            })}>
              <Heading
                as="h2" color="dark" fontSize="1.5rem"
                textTransform="capitalize" mb={2}
              >
                login now,
              </Heading>
              <Text
                mb={6} color="text" _firstLetter={{ textTransform: "capitalize" }}
                fontSize=".9rem"
              >
                login with your data and ensure that you account is correct.
              </Text>

              <Stack spacing={4}>
                <Input
                  type="text"
                  name="email"
                  label="email"
                  isRequired
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder="email@example.com"
                  isFormController
                  control={control}
                  rules={loginValidationSchema.email}
                  error={errors.email}
                />
                <Input
                  type="password"
                  name="password"
                  label="password"
                  isRequired
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder="password"
                  isFormController
                  control={control}
                  rules={loginValidationSchema.password}
                  error={errors.password}
                />
                <Flex
                  alignItems="center"
                >
                  <Checkbox size="lg" textTransform="capitalize" color="dark" alignItems="center" colorScheme="green">
                    remember my information
                  </Checkbox>
                </Flex>
                <Button
                  type="submit" height="45px" bg="primary"
                  color="light" textTransform="capitalize"
                  fontSize="1rem" border="1px solid transparent"
                  _hover={{ bg: "none", color: "primary", borderColor: "primary" }}
                  isLoading={auth.isLoading}
                >
                  Login
                </Button>
                <Flex
                  alignItems="center" color="dark" textTransform="capitalize"
                >
                  forget your password?
                  <CLink to="/" color="primary" ms={2}>reset now</CLink>
                </Flex>
              </Stack>
            </form>
          </Box>
        </SimpleGrid>
      </Container>
    </Flex >
  )
}

export default Login