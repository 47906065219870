import { Modal, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";

const GModal = ({
  onClose,
  size = "md",
  modalHeader,
  modalContent
}) => {
  return (
    <>
      <Modal isOpen={true} onClose={onClose} size={size}>
        <ModalOverlay />
        <ModalContent overflow="hidden">
          <ModalHeader
            textTransform="capitalize" color="light" bg="blackAlpha.900"
          >
            {modalHeader}
          </ModalHeader>
          {modalContent}
        </ModalContent>
      </Modal>
    </>
  )
}

export default GModal