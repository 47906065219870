import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import * as PiIcons from "react-icons/pi";
import * as FiIcons from "react-icons/fi";
import { toggleSidebar } from "../../store/sidebarSlice";
import { logout } from "features/auth/store/authSlice";
import { useNavigate } from "react-router-dom";
import routes from "navigations/routes";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const langs = [
    { id: 1, lang: "en" },
    { id: 2, lang: "ar" },
    // { id: 3, lang: "fr" },
    // { id: 4, lang: "it" }
  ];

  return (
    <Flex
      as="nav" paddingInline={4} alignItems="center"
      bg="light" height="70px"
    >
      <Flex alignItems="center" width="100%" justifyContent="space-between">
        <Button
          type="button" bg="transparent" p={0}
          _hover={{ bg: "transparent" }}
          onClick={() => dispatch(toggleSidebar())}
        >
          <PiIcons.PiListLight size={28} color="dark" />
        </Button>
        <Flex alignItems="center" gap={3}>
          <Button
            type="button" p={0} bg="none" height="auto"
            _hover={{ bg: "none" }} color="dark"
          >
            <FiIcons.FiMessageSquare size={20} />
          </Button>
          <Button
            type="button" p={0} bg="none" height="auto"
            _hover={{ bg: "none" }} color="dark"
          >
            <FiIcons.FiMail size={20} />
          </Button>

          <Menu>
            <MenuButton
              as={Button} p={0} bg="none" height="auto"
              _hover={{ bg: "none" }}
            >
              <Flex gap={2} paddingBlock={1} paddingInline={2} borderRadius="20px" bg="#EEE8F6">
                <FiIcons.FiGlobe size={20} style={{ margin: "auto" }} />
                <Avatar bg="green" size="xs" name={i18n.language} />
              </Flex>
            </MenuButton>
            <MenuList p={2} minW="auto">
              {langs.map(lang => (
                <MenuItem
                  as={Button} isDisabled={i18n.language === lang.lang}
                  key={lang.id} textTransform="capitalize"
                  onClick={() => i18n.changeLanguage(lang.lang)}
                >
                  {t(`general.${lang.lang}`)}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton
              as={Button} p={0} bg="none" height="auto"
              _hover={{ bg: "none" }}
            >
              <Avatar size="sm" name="mohamed mashhour" />
            </MenuButton>
            <MenuList p={2} minW="auto">
              <MenuItem>{t("layout.navbar.profile")}</MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch(logout());
                  navigate(routes.login)
                }}
              >
                {t("layout.navbar.logout")}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Navbar