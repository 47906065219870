import { createSlice } from "@reduxjs/toolkit";
import { login } from "../services/authService";

let initialState = {
  isLoading: false,
  token: null,
  user: null,
  errors: null
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      localStorage.removeItem("token");
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.token = null;
      state.user = null;
      state.errors = null;
    })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.token = payload.token;
        localStorage.setItem("token", payload.token);
        state.user = payload.fullName;
        state.errors = null;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.user = null;
        state.token = null;
        state.errors = payload.msg
      })
  }
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;