import { Box } from "@chakra-ui/react"
import React from 'react'

const BreadCrumb = ({
  children,
  bg = "transparent"
}) => {
  return (
    <Box mb={6}>
      {children}
    </Box>
  )
}

export default BreadCrumb