import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const getOffers = createAsyncThunk(
  "combos/getOffers",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Cascade/GetMenuList?statusid=${JSON.stringify({ StatusId: args.active })}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);