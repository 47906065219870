import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import BreadCrumb from "../../../components/ui/breadcrumb/BreadCrumb";

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <>
      <BreadCrumb>
        <Box
          as="p" fontSize="1.6rem" textTransform="capitalize"
        >
          {t("general.welcome")}, Our value customer
        </Box>
      </BreadCrumb>
      <Outlet />
    </>
  )
}

export default Dashboard