import * as BiIcons from "react-icons/bi";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import routes from "../../navigations/routes";

const links = [
  {
    path: routes.dashboard,
    icon: <BiIcons.BiSolidDashboard size={18} />,
    text: "layout.sidebar.dashboard",
    subLinks: null
  },
  {
    path: routes.pending_offers,
    icon: <FaIcons.FaClipboardCheck size={18} />,
    text: "layout.sidebar.pending_offers",
    subLinks: null
  },
  {
    path: routes.not_eligible_users,
    icon: <RiIcons.RiKeyFill size={18} />,
    text: "layout.sidebar.not_eligible_users",
    subLinks: null
  },
  {
    path: routes.offers_list,
    icon: <FaIcons.FaClipboardCheck size={18} />,
    text: "layout.sidebar.offers_list",
    subLinks: null
  }
];

export default links;