import { Navigate, Outlet } from "react-router-dom";
import AppLayout from "../layout/AppLayout";
import routes from "./routes";

const ProtectedRoutes = () => {
  const isAuth = localStorage.getItem("token");
  return (isAuth && isAuth !== "") ? (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ) : (
    <Navigate to={routes.login} />
  )
}

export default ProtectedRoutes