import { createAsyncThunk } from "@reduxjs/toolkit";

export const login = createAsyncThunk(
  "auth/login",
  async (args, thunkApi) => {
    try {
      if (args.email !== "admin@niyat.sa" || args.password !== "12345678") {
        return thunkApi.rejectWithValue({ message: "invaild login, please try again" });
      }
      return thunkApi.fulfillWithValue({ user: "", token: "token" });
    } catch (e) {
      return thunkApi.rejectWithValue({ message: "invaild login, please try again" });
    }
  }
)