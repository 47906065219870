import IAxios from "axios";

const axios = IAxios.create({
  timeout: 10000,
  baseURL: "https://cascadeapi.mygatein.com",
});

axios.interceptors.request.use(config => {
  return config;
})

export { axios };