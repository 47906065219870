import { Box, Text } from "@chakra-ui/react"

const NoData = ({ title }) => {
  return (
    <Box bg="light" paddingInline={4} paddingBlock={8} textAlign="center" borderRadius={2}>
      <Text textTransform="capitalize" color="text" fontSize="1.1rem">
        {title}
      </Text>
    </Box>
  )
}

export default NoData