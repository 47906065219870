import { useTranslation } from "react-i18next";
import { Avatar, Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import * as FiIcons from "react-icons/fi";
import routes from "navigations/routes";

const CTable = ({ page, offers, handleOpenDetailsModal }) => {
  const { t } = useTranslation();
  return (
    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.offers.full_name")}</Th>
            <Th>{t("pages.offers.national_id")}</Th>
            <Th>{t("pages.offers.name")}</Th>
            <Th>{t("pages.offers.amount")}</Th>
            <Th>{t("pages.offers.tnure")}</Th>
            <Th>{t("pages.offers.installment")}</Th>
            <Th>{t("pages.offers.type")}</Th>
            <Th>{t("pages.offers.create_date")}</Th>
            {page === routes.pending_offers && (
              <Th>{t("general.action")}</Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {offers.map((offer, index) => (
            <Tr key={offer.Id}>
              <Td>{index + 1}</Td>
              <Td>{offer.fullName}</Td>
              <Td>{offer.NationalId}</Td>
              <Td>{offer.Name}</Td>
              <Td>{offer.amount}</Td>
              <Td>{offer.Tnure}</Td>
              <Td>{offer.Installment}</Td>
              <Td>{offer.Type}</Td>
              <Td>{new Date(offer.CreateDate).toLocaleDateString()}</Td>
                <Td>
                  <Flex gap={4} alignItems="center" flexWrap="wrap">
                    <button type="button" onClick={() => handleOpenDetailsModal(offer.UserId)}>
                      <FiIcons.FiEye size={22} color="#507395" />
                    </button>
              {page === routes.pending_offers && (
                <>
                    <Avatar
                      as="button" size="xs" bg="green"
                      icon={<FiIcons.FiCheck size={16} />}
                      onClick={() => console.log("clicked")}
                    />
                    <Avatar
                    as="button" size="xs" bg="red.600"
                    icon={<FiIcons.FiX size={16} />}
                    onClick={() => console.log("clicked")}
                    />
                  </>
                    )}
                  </Flex>
                </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default CTable