import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Text, Spinner } from "@chakra-ui/react"
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import NoData from "components/ui/noData/NoData";
import DetailsModal from "../components/modals/DetailsModal";
import Table from "../components/Table";
import { getOffers } from "../services/offersService";

const Offers = ({ active, page }) => {
  const dispatch = useDispatch();
  const offers = useSelector(state => state.offers);
  const { t } = useTranslation();

  const [showDetailsModal, setShowDetailsModal] = useState(false);

  useEffect(() => {
    dispatch(getOffers({ active }));
  }, [dispatch, active]);

  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.offers.offers")}
          </Text>
        </Flex>
      </BreadCrumb>

      {offers.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : offers.data.length === 0 ? (
        <NoData title={t("pages.offers.no_data_hint")} />
      ) : (
        <Table
          page={page}
          offers={offers.data}
          handleOpenDetailsModal={(e) => setShowDetailsModal(e)}
        />
      )}

      {showDetailsModal && (
        <DetailsModal
          data={showDetailsModal}
          onClose={() => setShowDetailsModal(false)}
        />
      )}
    </>
  )
}

export default Offers