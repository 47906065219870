import { configureStore } from "@reduxjs/toolkit";
import sidebarSlice from "./sidebarSlice";
// auth
import authSlice from "features/auth/store/authSlice";
import offersSlice from "features/offers/store/offersSlice";

const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    auth: authSlice,
    offers: offersSlice
  }
});

export default store;