import { Routes, Route } from "react-router-dom";
import routes from "./routes";
import ProtectedRoutes from "./ProtectedRoutes";

import Login from "../features/auth/pages/Login";

import Dashboard from "../features/dashboard/pages/Dashboard";
import Offers from "features/offers/pages/Offers";

const Navigations = () => {
  return (
    <Routes>
      <Route path={routes.login} element={<Login />} />
      <Route element={<ProtectedRoutes displayLayout />}>
        <Route
          path={routes.dashboard}
          element={<Dashboard />}
        />
        <Route
          path={routes.pending_offers}
          element={<Offers page={routes.pending_offers} active="1" />}
        />
        <Route
          path={routes.not_eligible_users}
          element={<Offers page={routes.not_eligible_users} active="2" />}
        />
        <Route
          path={routes.offers_list}
          element={<Offers page={routes.offers_list} active="3" />}
        />
      </Route>
    </Routes>
  )
}

export default Navigations